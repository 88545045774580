















import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  watch,
} from '@vue/composition-api'
import { useGetters, useState, useActions, useMutations } from '@u3u/vue-hooks'
import axios from 'axios'

import EanInfo from '@/components/my/EanInfo.vue'
import { getApiUrl } from '@/inc/app.config'
import { Ean } from '@/inc/types'
import { formatIndexHistory } from '@/inc/utils'

export default defineComponent({
  name: 'my-ean',
  components: { EanInfo },

  setup(_props, ctx) {
    const { $logger, $route } = ctx.root
    const { id, cpt } = $route.params
    const userState = useState(['user'])
    const { getEan } = useGetters('user', ['getEan'])
    const meter = getEan.value(id)
    const { hasData } = useGetters('my', ['hasData'])
    const { i18n } = useState('my', ['i18n'])
    const { UPDATE_DATA } = useMutations('my', ['UPDATE_DATA'])
    const { fetchSmartData } = useActions('my', ['fetchSmartData'])
    // const { refreshUser } = useActions('user', ['refreshUser'])
    const { currentLang } = useGetters(['currentLang'])
    const isLoading = ref(true)

    const indexData = ref<Record<string, any>>({})
    const billingData = ref<Record<string, any>>({})
    const invoiceData = ref<Record<string, any>>({})
    const meterData = ref<Record<string, any>>({})
    // const ean = computed(() =>
    //   userState?.user?.value?.listeEan.find(
    //     (item: Ean) => item.ean === ctx.root.$route.params.id
    //   )
    // )
    const myEan = ref()

    onBeforeMount(async () => {
      isLoading.value = true
      if (userState.user.value.listeEan !== undefined) {
        await axios
          .get(`${getApiUrl()}/index/history`, { params: { Ean: id } })
          .then(res => {
            indexData.value = formatIndexHistory(res.data?.contrat) || {}
            UPDATE_DATA({ key: 'index', value: true })
          })
          .catch($logger.error)

        await axios
          .get(`${getApiUrl()}/ean/${id}`, {
            params: { NumCpt: cpt },
          })
          .then(res => {
            meterData.value = res.data
            UPDATE_DATA({ key: 'meter', value: true })
            myEan.value = res.data
            isLoading.value = false
          })
          .catch($logger.error)

        if (meterData.value.ccHgz) {
          await axios
            .get(`${getApiUrl()}/billing/balance?Ean=${id}&Langue=FR`)
            .then(res => {
              billingData.value = res.data
              UPDATE_DATA({ key: 'billing', value: true })
            })
            .catch($logger.error)

          await axios
            .get(`${getApiUrl()}/billing/invoices?Ean=${id}&Langue=FR`)
            .then(res => {
              invoiceData.value = res.data
              UPDATE_DATA({ key: 'invoice', value: true })
            })
            .catch($logger.error)
        }

        if (meter?.profile?.smartMeter) {
          fetchSmartData({ ean: id, numCpt: cpt })
        }
        isLoading.value = false
      }
      isLoading.value = false
    })

    watch(currentLang, () => {
      // refreshUser()
    })

    return {
      // ean,
      meter,
      hasData,
      indexData,
      meterData,
      billingData,
      invoiceData,
      i18n,
      ...useGetters('user', ['isFetching']),
      myEan,
      isLoading,
    }
  },
})
